/*
|--------------------
|       Contact
|--------------------
*/
.page-template-contact {
  #header {
    background-color: $blue;
    &.fixed,
    &.fixedup{
      background-color: white;
    }
  }

  .details {
    .adr{
      font-family: "GT Alpina Trial - Th";
      font-size: 16px;
      line-height: 24px;
    }
    .c-gold{
      font-family: "FS Meridian";
      font-size: 16px;
      font-weight: 400;
    }
    a {
      color: #ffffff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      font-family: "GT Alpina Trial - Th";
      font-size: 16px;
      line-height: 24px;
      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }
  }

  form {
    .top_label {
      margin: 0;
    }

    .gfield {
      margin-bottom: 15px;
    }

    .gfield_required {
      color: $blue;
      font-size: 24px;
    }

    label {
      color: #333333;
      font-family: "Cinzel";
      font-size: 24px;
      font-weight: 400;
    }

    select {
      border: none;
      border-bottom: 1px solid #181d24;
      font-family: "GT Alpina Trial - Th";
      font-size: 24px !important;
      line-height: 27px;
      background-color: $beige;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 8px;
        background-position: left;
        background-repeat: no-repeat;
        background-image: url('../img/icon/arr-gold.svg');
        transform: rotate(-90deg);
        pointer-events: none;
      }
    }

    input[type=text] {
      border: none;
      padding: 0;
      font-family: "GT Alpina Trial - Th";
      font-size: 24px !important;
      line-height: 27px;
      border-bottom: 1px solid #181d24;
    }

    textarea {
      height: 150px!important;
      padding: 20px;
      border: none;
      border: 1px solid $blue;
      text-transform: initial;

      &::placeholder {
        color: #333333;
        opacity: 0.5;
        font-family: "Cinzel";
        font-size: 24px;
        font-weight: 400;
      }
    }

    .gform_footer {
      text-align: center;
    }

    .gfield_validation_message {
      padding: 5px 10px;
      margin: -1px 0 0px 0;
    }

    .gfield_consent_label {
      color: #333333;
      font-family: "Work Sans";
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }


    .ginput_container_consent {
      color: #333333;
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      font-weight: 400;
      position: relative;

      a {
        text-decoration: underline;
      }

      .gfield_required {
        display: none;
      }

      input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        border: none;
        left: 0;
        top: 5px;
        padding: 0;
        margin: 0;
      }

      input[type=checkbox]:before {
        content: "";
        display: block;
        position: absolute;
        width: 21px;
        height: 21px;
        top: 0;
        left: 0;
        border: 1px solid $blue;
        background-color: transparent;
      }

      input[type=checkbox]:checked:after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 3px;
        left: 3px;
        background: $gold;
      }

      label {
        margin-left: 40px;
        color: #333333;
        font-family: "FS Meridian";
        font-size: 16px !important;
        font-weight: 300;
        line-height: 24px;

        @include media-breakpoint-down(lg) {
          padding-right: 0px;
        }
      }
    }
  }

  .ginput_container_checkbox{
    margin-bottom: 40px;
  }

  .gform_validation_errors {
    margin-bottom: 30px;
    border-radius: 0px;

    .gform_submission_error {
      font-size: 15px !important;
      font-family: "Work Sans" !important;
    }
  }

  .gform_title {
    display: none;
  }


}
