/*
|--------------------
|       404
|--------------------
*/

#page-404 {
  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: $white;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .item-title {
      @extend .title-xxl;
      font-size: 8vw;
      font-weight: 300;
      line-height: 1;
    }
  }
}
