/*
|--------------------
|      SINGLE
|--------------------
*/

.single-annonces,
.single-realisation {
  .fiche {
    overflow: hidden;
    background-color: $blue;
  }

  h1 {
    color: #ffffff;
  }

  .swiper-container {
    position: relative;
    width: 100%;
    height: 600px;
    @include media-breakpoint-down(sm) {
      height: 400px;
    }
    @include media-breakpoint-down(xs) {
      height: 250px;
    }
  }

  .swiper-container > .swiper-slide__content {
    position: absolute;
    top: 0;
  }

  .swiper-wrapper {
    //margin-top: 135px;

    .swiper-slide {
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 400ms linear;
      transform: scale(0.9);
      z-index: 0;
      opacity: 0;
      @include media-breakpoint-down(sm) {
        height: 400px;
      }
      @include media-breakpoint-down(xs) {
        height: 250px;
      }

      img{
        max-height: 600px;
        @include media-breakpoint-down(sm) {
          max-height: 400px;
        }
        @include media-breakpoint-down(xs) {
          max-height: 250px;
        }
      }

      .legend {
        position: absolute;
        bottom: -12px;
        color: white;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        padding: 8px 5px;
        i{
          color: $gold;
          font-style:normal;
        }
      }

      &.swiper-slide-active {
        transform: scale(1);
        z-index: 1;
        opacity: 1;
      }

      &.swiper-slide-prev,
      &.swiper-slide-next {
        opacity: 0.4;
      }

    }
  }

  .swiper-button-anno {
    width: 100%;
    height: 80vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .swiper-button-anno-prev,
    .swiper-button-anno-next {
      width: 50%;
      height: 100%;
      top: 0;
      margin: 0;
      transition: cursor 0.3s;
      position: absolute;
      z-index: 1;

      &::after {
        display: none;
      }
    }

    .swiper-button-anno-prev {
      left: 0;
      transform: rotate(-90deg);
      //cursor: url(../img/icon/arr-l.png) 50 50, auto;
    }

    .swiper-button-anno-next {
      right: 0;
      //cursor: url(../img/icon/arr-r.png) 50 50, auto;
    }
  }


  .swiper-slide__content {
    height: 300px;
  }

  .fiche-details,
  .fiche-details2 {
    color: #ffffff;

    span {
      padding: 0 15px;
      color: $gold;
    }

    .price {
      i {
        font-style: initial;
        padding-left: 8px;
        color: $gold;
      }
    }

  }

  .fiche-details2 {
    .txt-l {
      text-align: left;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .txt-r {
      text-align: right;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .bdt {
      height: 1px;
      padding: 0;
      margin: auto;
      width: 1240px;
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0.2;
      background-color: #fff;
      @include media-breakpoint-down(xl) {
        width: 1040px;
      }
      @include media-breakpoint-down(lg) {
        width: 860px;
      }
      @include media-breakpoint-down(md) {
        width: 690px;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .section-plus {
    h3 {
      font-family: "FS Meridian";
      font-size: 40px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 48px;

      i {
        font-weight: 400;
        font-style: initial;
        font-family: "FS Meridian";
      }
    }

    .plus {
      font-family: "FS Meridian";
      font-size: 16px;
      font-weight: 400;

      img {
        max-height: 38px;
      }

      .label {
        height: 45px;
        padding-top: 15px;
        line-height: initial;
      }

      .txtlabel {

      }

      .txtlabel2 {
        opacity: 0.7;
        font-weight: 300;
      }
    }
  }

  .map {
    img {
      max-width: inherit;
      margin: auto;
      display: block;
      width: 100%;
    }
  }

  #section-res {
    width: 100%;

    span {
      display: block;
    }

    h2 {
      transform: scale(0.95);
      transition: all 200ms linear;
    }

    &:hover {
      h2 {
        transform: scale(1);
        transition: all 200ms linear;
      }
    }
  }
}



