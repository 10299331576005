/*
|--------------------
|      ANNONCES
|--------------------
*/

#page-annonces-archive {
  h1 {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .custom-card {
    .card-img-container {
      height: 390px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      transition: all ease 0.4s;
      @include media-breakpoint-down(sm) {
        height: 290px;
      }
    }

    .card-content {
      border-top: 1px solid #383a3e;
      border-bottom: 1px solid #383a3e;

      &:before {
        content: "";
        height: 1px;
        width: 0%;
        background-color: #383a3e;
        position: absolute;
        top: 0;
        left: 0;
        transition: all ease 0.4s;
      }

      &:after {
        content: "";
        height: 1px;
        width: 0%;
        background-color: #383a3e;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all ease 0.4s;
      }
    }

    &:hover {
      .card-content {
        &:before {
          content: "";
          width: 100%;
          background-color: $gold;
          transition: all ease 0.4s;
        }

        &:after {
          content: "";
          width: 100%;
          background-color: $gold;
          transition: all ease 0.4s;
        }
      }

      .card-img-container {
        border-top-right-radius: 90px;
        border-bottom-left-radius: 90px;
        transition: all ease 0.4s;
      }
    }
  }

  h2 {
    color: $gold;
    font-family: "FS Meridian";
    font-size: 24px;
    font-weight: 300;
  }

  .annonces {
    .details {
      color: #ffffff;
      font-family: "FS Meridian";
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
    }

    .details-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #ffffff;
      font-family: "FS Meridian";
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
    }

  }

  .cms,
  h4 {
    color: #ffffff;

    p {
      color: #ffffff;
    }
  }


  .js-filterable {
    opacity: 1;
    display: block;
    transition: all ease 0.4s;
  }

  .is-hidden {
    opacity: 0;
    display: none;
    transition: all ease 0.4s;
  }

  .filters {
    font-family: "FS Meridian";
    font-size: 25px;
    font-weight: 300;
    display: flex;
    position: absolute;
    right: 0;
    bottom: 6px;
    @include media-breakpoint-down(md) {
      display: block;
      position: relative;
      padding-top: 30px;
      text-align: center;
    }

    li {
      padding: 0 10px;
      @include media-breakpoint-down(md) {
        display: inline-block;
      }

      &.title-filter {
        font-weight: 100;
        @include media-breakpoint-down(sm) {
          display: block;
          text-align: center;
        }
      }

      a {
        opacity: 0.6;
        color: #333333;
        font-family: "FS Meridian";
        font-size: 25px;
        font-weight: 100;

        &.active {
          opacity: 1;
          color: #333333;
          font-family: "FS Meridian";
          font-size: 25px;
          font-weight: 400;
        }
      }
    }
  }


  .tabs {
    display: flex;
    list-style-type: none;
    overflow-x: auto;

    a {
      display: block;
      padding: 10px;
      text-decoration: none;
      text-transform: capitalize;
      border-bottom: 2px solid transparent;
      position: relative;
      margin-bottom: 0;

      &.active::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: tomato;
        position: absolute;
        bottom: -2px;
        left: 0;
        border-radius: 4px;
      }
    }
  }

  .tab-panel {
    display: none;

    &.active {
      display: flex;
    }
  }

}
