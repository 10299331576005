/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  background-color: $beige;
  padding: 20px 0 18px 0;

  a {
    transition: all ease 0.3s;
    &:hover {
      color: $gold;
      transition: all ease 0.3s;
    }
  }
  #ot-sdk-btn{
    padding: 0;
    color: #000;
    font-family: "FS Meridian";
    font-size: 16px;
    font-weight: 400;
    background: none;
    border: none;
    transition: all ease 0.3s;
    &:hover {
      color: $gold;
      transition: all ease 0.3s;
    }
  }
}

pre.dump {
  display: block;
  width: 100%;
  clear: both;
  color: #000 !important;
  background: #ccc !important;
  text-transform: none !important;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}