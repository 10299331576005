/*
|--------------------
|      ANNONCES
|--------------------
*/

#page-invest {
  overflow: hidden;

  .section-intro {

    .detail-img {
      padding: 0 50px;
      margin-top: -35px;

      @include media-breakpoint-down(xl) {
        padding: 0 30px;
      }
      @include media-breakpoint-down(lg) {
        padding: 0px;
      }
      @include media-breakpoint-down(md) {
        padding: 0;
        position: relative;
        img {
          margin: auto;
          display: block;
          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }
      }

      .img-d {
        height: 348px;
        width: 100%;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(xl) {
          height: 268px;
          max-width: 350px;
          margin: auto;
        }
      }
    }

    .details {
      padding: 20px 50px;
      @include media-breakpoint-down(xl) {
        padding: 20px 30px;
      }
      @include media-breakpoint-down(lg) {
        padding: 20px 0px;
      }
      @include media-breakpoint-down(md) {
        text-align: center;
        padding: 40px 0;
      }

      h2 {
        color: #ffffff;
        font-family: "GT Alpina Trial - Th";
        font-size: 40px;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
        }
      }

      .text {
        opacity: 0.8;
        color: #ffffff;
        font-family: "FS Meridian";
        font-size: 16px;
        line-height: 24px;
      }
    }

    .bg {
      background-size: cover;
      background-position: center;
      width: 100%;
      position: relative;
      @include media-breakpoint-up(lg) {
        height: 750px;
        margin-top: -35px;
      }
      @include media-breakpoint-down(md) {
        height: 400px;
      }
    }
  }

  .section-prestation {
    .item-presta {
      display: block;
      cursor: pointer;

      transition: margin .4s;

      h3 {
        color: #ffffff;
        font-family: "GT Alpina Trial - Th It";
        font-size: 64px;
        opacity: 0.6;
        transition: all linear 0.4s;

        .nbr {
          display: block;
          float: left;
          position: relative;

          &:before {
            content: "";
            height: 1px;
            width: 0;
            position: absolute;
            bottom: 0;
            transition: all linear 0.4s;
            background-color: $gold;
          }
        }

        .title {
          float: left;
          padding-left: 20px;
          font-size: 31px;
          height: 70px;

          span {
            position: relative;
            z-index: 1;

            &:before {
              content: "";
              height: 1px;
              width: 0;
              position: absolute;
              bottom: 0;
              transition: all linear 0.4s;
              background-color: $gold;
            }
          }
        }

        .plus {
          font-size: 49px;
          top: 4px;
          left: 10px;
          position: relative;
          opacity: 0;
          display: inline-block;
          transition: all linear 0.4s;
          color: $gold;
          z-index: 1;
        }
      }

      .bg {
        opacity: 0.6;
        border-top-left-radius: 120px;
        border-bottom-right-radius: 120px;
        position: absolute;
        width: 440px;
        height: 230px;
        top: 90px;
        left: 0;
        right: 0;
        margin: auto;
        background-size: cover;
        background-position: center;
        display: none;
        @include media-breakpoint-down(md) {
          position: relative;
          top: initial;
        }
      }

      .cms {
        color: #FFFFFF;
        font-family: "FS Meridian";
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        opacity: 0;
        display: none;
        z-index: 1;
        position: relative;
        transition: all ease 0.4s;
        @include media-breakpoint-down(md) {
          padding-top: 20px;
        }

        p {
          color: #FFFFFF;
          font-family: "FS Meridian";
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
      }

      &:hover,
      &.active {
        h3 {
          opacity: 1;
          transition: all linear 0.4s;

          .nbr {
            @include media-breakpoint-up(md) {
              &:before {
                content: "";
                width: 100%;
                transition: all linear 0.4s;
              }
            }
          }

          .title {
            span {
              @include media-breakpoint-up(md) {
                &:before {
                  content: "";
                  width: 100%;
                  transition: all linear 0.4s;
                }
              }
            }
          }
        }

        .plus {
          opacity: 1;
          display: inline-block;
          transition: all linear 0.4s;
        }
      }

        &.active {
          min-height: 300px;
        }
    }
  }

  .section-conseil {
    .item {
      h3 {
        color: #ffffff;
        font-family: "GT Alpina Trial - Th";
        font-size: 40px;
        height: 100px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: -84px;
        z-index: 1;
        @include media-breakpoint-down(md) {
          top: initial;
          font-size: 36px;
          height: initial;
          position: relative;
          paddin-bottom: 30px;
        }

        div {
          margin: auto;
          text-align: center;
        }
      }

      .bg {
        width: 100%;
        height: 180px;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 200px;
        border-top-right-radius: 200px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0.4;
          background: $blue;
          z-index: 0;
        }

        @include media-breakpoint-down(md) {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }

    .cms {
      color: white;

      p {
        color: white;
      }
    }
  }

  .section-realisation {
    .custom-card {
      border-top: 1px solid #383a3e;
      position: relative;

      &:before {
        content: "";
        height: 1px;
        width: 0%;
        background-color: #383a3e;
        position: absolute;
        top: 0;
        left: 0;
        transition: all ease 0.4s;
      }

      .custom-card-link {
        display: block;
      }

      .card-img-container {
        position: relative;
        overflow: hidden;

        .details {
          color: white;
          font-family: "FS Meridian";
          font-size: 12px;
          font-weight: 300;
          text-transform: uppercase;
          @include media-breakpoint-down(lg) {
            font-size: 10px;
          }
        }

        .details-bottom {
          width: 100%;
        }

        .block-card-img {
          width: 100%;
          height: 250px;
          overflow: hidden;

          .card-img {
            width: 100%;
            height: 250px;
            transition: transform 2s $easeOutExpo;
            will-change: transform;
          }
        }

        .cms {
          color: white;

          p {
            color: white;
          }
        }
      }

      &:hover {
        .card-img-container {
          .block-card-img {
            border-top-right-radius: 90px;
            border-bottom-left-radius: 90px;
            transition: all ease 0.4s;
          }

          .card-img {
            transform: scale(1.05);
          }
        }

        &.custom-card {
          &:before {
            content: "";
            width: 100%;
            background-color: $gold;
            transition: all ease 0.4s;
          }
        }
      }
    }
  }

}

.page-template-investissement {
  .mouse_glass {
    &:before {
      content: "";
      width: 22px;
      height: 22px;
      transform: rotate(90deg);
      background-image: url('../img/icon/glass.svg');
    }
  }
}