/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  @extend .bg-cover;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: $black;
  @include media-breakpoint-down(sm) {
    //min-height: initial;
  }

  .item-content {
    position: absolute;
    bottom: 70px;
    width: 100%;
    padding: 0;
    @include media-breakpoint-down(sm) {
      bottom: 15px;
    }
  }
}

#map{
  height: 500px;
}


/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.annonces {
  .custom-card {

    .custom-card-link {
      display: block;
    }

    /*
    * Card img
    */
    .card-img-container {
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .card-img {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        transition: transform 2s $easeOutExpo;
        will-change: transform;
      }
    }

    /*
    * Card content
    */
    .card-content {

      .date {
        color: rgba($very-dark-grey, 0.6);
      }
    }

    /*
    * Hover state
    */
    &:hover {

      .card-img-container {

        .card-img {
          transform: scale(1.05);
        }
      }
    }


  }
}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none !important;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }


  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 30px;
  }

  h1 {
    @extend .title-xxl;
  }

  h2 {
    @extend .title-xl;
  }

  h3 {
    @extend .title-lg;
  }

  h4 {
    @extend .title-md;
  }

  h5 {
    @extend .title-sm;
  }

  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url('../img/icon/quote-left.svg');

      width: 50px;
      height: 50px;


      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url('../img/icon/quote-right.svg');

      width: 50px;
      height: 50px;


      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 155px;
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.question {
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 900px;
  color: #fff;
  @include media-breakpoint-down(md) {
    height: 480px;
    text-align: center;
  }

  .cms {
    color: #fff;

    p {
      color: #fff;
    }
  }
}


.section-service {
  .item-service {
    height: 700px;
    background-position: center;
    background-size: cover;
    position: relative;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    transition: all ease 0.4s;
    @include media-breakpoint-down(md) {
      height: 500px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: opacity ease 0.4s;
      background-color: #fff;
    }

    .num {
      color: #ffffff;
      font-family: "GT Alpina Trial - Th It";
      font-size: 64px;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 54px;
      }

      &:before {
        content: "";
        height: 1px;
        width: 0px;
        margin: auto;
        text-align: center;
        display: inline-block;
        background-color: $gold;
        position: absolute;
        right: 0;
        left: 12px;
        transition: all ease 0.4s;
        bottom: 5px;
      }
    }

    h3 {
      color: #ffffff;
      font-family: "GT Alpina Trial - Th";
      font-size: 36px;
      position: absolute;
      left: 0;
      right: 0;
      top: 82%;
      height: 92px;
      transition: all ease 0.4s;
      @include media-breakpoint-down(md) {
        top: 72%;
      }

      div {
        width: 88%;

        span {
          display: block;

          &.w1 {
            left: 0;
            right: 0;
            top: 0;
            margin: auto;
            position: absolute;
            transition: all ease 0.4s;
          }

          &.w2 {
            left: 0;
            right: 0;
            top: 45px;
            margin: auto;
            position: absolute;
            transition: all ease 0.4s;
          }
        }
      }
    }

    .arrow-service {
      width: 36px;
      height: 30px;
      display: block;
      background-image: url('../img/global/arrow-b.svg');
      z-index: 1;
      margin: auto;
      position: absolute;
      left: -60px;
      right: 0;
      bottom: 63px;
      opacity: 0;
      transition: all ease 0.4s;
    }

    &:hover {
      border-bottom-left-radius: 90px;
      border-top-right-radius: 90px;
      transition: all ease 0.4s;
      @include media-breakpoint-down(md) {
        border-bottom-left-radius: 45px;
        border-top-right-radius: 45px;
      }

      .num {
        color: #000;
        transition: all ease 0.4s;
        @include media-breakpoint-down(md) {
          font-size: 54px;
        }

        &:before {
          content: "";
          width: 75px;
          transition: all ease 0.4s;
        }
      }

      h3 {
        top: 42%;
        color: #000000;
        font-size: 36px;
        transition: all ease 0.4s;
        transform: skewX(-20deg);

        div {
          width: 88%;

          span {
            display: block;

            &.w1 {
              left: 5%;
              right: 0%;
              top: 0;
              text-align: left;
              position: absolute;
              transition: all ease 0.4s;
            }

            &.w2 {
              right: 5%;
              left: 0%;
              top: 45px;
              text-align: right;
              position: absolute;
              transition: all ease 0.4s;
            }
          }
        }
      }

      &:before {
        opacity: 0.6;
        transition: all ease 0.4s;
        border-bottom-left-radius: 90px;
        border-top-right-radius: 90px;
        @include media-breakpoint-down(md) {
          border-bottom-left-radius: 45px;
          border-top-right-radius: 45px;
        }
      }

      .arrow-service {
        opacity: 1;
        left: 0;
        bottom: 63px;
        transition: all ease 0.4s;
      }
    }
  }
}


/*
|
| hide onetrust button
|---------------
*/
#ot-sdk-btn-floating {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}