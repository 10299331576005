/*
|--------------------
|      ANNONCES
|--------------------
*/

#page-about {
  overflow: hidden;

  h1{
    font-size: 60px;
    @include media-breakpoint-down(xl) {
      font-size: 50px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 45px;
    }
  }

  .section-smart-invest {
    @include media-breakpoint-up(lg) {
      height: 801px;
    }

    .details {
      @include media-breakpoint-up(lg) {
        //bottom: 0;
        //position: absolute;
        padding: 0 70px;
      }
      @include media-breakpoint-down(xl) {
        padding: 0 30px;
      }
      @include media-breakpoint-down(lg) {
        padding: 0 15px;
      }
      @include media-breakpoint-down(md) {
        text-align: center;
        padding: 40px 0;
      }

      h2 {
        color: white;
      }

      .text {
        color: #ffffff;
        font-family: "FS Meridian";
        font-size: 16px;
      }
    }

    .bg {
      background-size: cover;
      background-position: center;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 750px;
        margin-top: -35px;
      }
      @include media-breakpoint-down(md) {
        height: 400px;
      }
    }
  }

  .section-citation {
    position: relative;

    .text {
      .citation {
        color: #ffffff;
        font-family: "FS Meridian";
        font-size: 34px;
        line-height: 48px;
        font-weight: 400;
        z-index: 1;
        position: relative;
        padding: 0 40px;
        @include media-breakpoint-down(md) {
          font-size: 24px;
          padding: 0 25px;
          line-height: 38px;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 23px;
          height: 68px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../img/icon/quote.svg');
          @include media-breakpoint-down(md) {
            width: 13px;
            height: 58px;
          }
        }

        //&:after {
        //  content: "";
        //  position: absolute;
        //  right: 0;
        //  bottom: 0;
        //  width: 23px;
        //  height: 68px;
        //  background-size: contain;
        //  background-repeat: no-repeat;
        //  background-image: url('../img/icon/quote.svg');
        //  transform: rotate(180deg);
        //  @include media-breakpoint-down(md) {
        //    width: 13px;
        //    height: 58px;
        //  }
        //}
        img{
          height: 68px;
          width: 23px;
          display: inline;
          transform: rotate(180deg);
          @include media-breakpoint-down(md) {
            width: 13px;
            height: 58px;
          }
        }
      }

      .autor {
        color: #ffffff;
        font-family: "GT Alpina Trial - Th";
        font-size: 40px;
        padding: 0 40px;
        z-index: 1;
        position: relative;
        @include media-breakpoint-down(md) {
          font-size: 32px;
        }

        i {
          color: $gold;
          font-family: "GT Alpina Trial - Th It";
        }
      }

      .role {
        color: $gold;
        font-family: "FS Meridian";
        font-size: 16px;
        padding: 0 40px;
        z-index: 1;
        position: relative;
      }
    }

    .photo {
      right: 0;
      position: absolute;
    }

    img {
      height: 650px;
      @include media-breakpoint-down(md) {
        margin: auto;
        display: block;
        height: 420px;
      }
    }
  }

  .section-proximite {
    position: relative;
    margin-top: -1px;

    h2 {
      text-align: center;
      color: white;
      position: absolute;
      left: 0;
      right: 0;
      top: 66px;
      margin: auto;
      display: inline-table;
      z-index: 1;
      @include media-breakpoint-down(lg) {
        top: 96px;
      }
      @include media-breakpoint-down(md) {
        top: 136px;
      }

      i {
        float: right;
        margin-right: -50px;
        @include media-breakpoint-down(sm) {
          float: initial;
          margin-right: initial;
        }
      }
    }

    .bg {
      height: 750px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      border-top-right-radius: 200px;
      border-bottom-left-radius: 200px;
      @include media-breakpoint-down(lg) {
        height: 600px;
      }
      @include media-breakpoint-down(sm) {
        height: 500px;
        border-top-right-radius: 80px;
        border-bottom-left-radius: 80px;
      }

      //&:before {
      //  content: "";
      //  background-color: $blue;
      //  opacity: 0.8;
      //  position: absolute;
      //  top: 0;
      //  bottom: 0;
      //  left: 0;
      //  right: 0;
      //}

      .text {
        color: #ffffff;
        font-family: "FS Meridian";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .section-valeur {
    .item-valeur {
      width: 100%;
      height: 600px;
      background-size: cover;
      background-position: center;
      position: relative;
      @include media-breakpoint-down(md) {
        height: initial;
        display: flex;
        vertical-align: middle;
        align-items: center;
      }

      &.v1 {
        margin-top: 250px !important;
        height: 350px;
        border-top-right-radius: 90px;
        @include media-breakpoint-down(md) {
          margin-top: 0px !important;
          height: initial;
          border-top-right-radius: 0px;
        }
      }

      &.v3 {
        height: 350px;
        border-top-left-radius: 150px;
        @include media-breakpoint-down(md) {
          height: initial;
          border-top-left-radius: 0px;
        }
      }

      .item-valeur-1 {
        position: absolute;
        top: -250px;
        width: 100%;
        background-color: $blue;
        @include media-breakpoint-down(md) {
          background-color: transparent;
          position: relative;
          padding: 60px 15px;
          top: initial;
        }

        .text {
          border-top-left-radius: 90px;
          border-bottom-right-radius: 90px;
          @include media-breakpoint-down(md) {
            padding: 30px 15px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 40px;
            border-bottom-right-radius: 40px;
          }
        }
      }

      .item-valeur-2 {
        width: 100%;
        background-color: $blue;
        @include media-breakpoint-down(md) {
          background-color: transparent;
          padding: 60px 15px;
        }

        .text {
          border-top-right-radius: 90px;
          border-bottom-left-radius: 90px;
          @include media-breakpoint-down(md) {
            padding: 30px 15px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 40px;
            border-bottom-right-radius: 40px;
          }
        }
      }

      .item-valeur-3 {
        position: absolute;
        bottom: -250px;
        width: 100%;
        background-color: $blue;
        @include media-breakpoint-down(md) {
          background-color: transparent;
          position: relative;
          padding: 60px 15px;
          top: initial;
          bottom: initial;
        }

        .text {
          border-top-left-radius: 90px;
          border-bottom-right-radius: 90px;
          @include media-breakpoint-down(md) {
            padding: 30px 15px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 40px;
            border-bottom-right-radius: 40px;
          }
        }
      }

      .text {
        color: #ffffff;
        font-family: "GT Alpina Trial - Th";
        font-size: 32px;
        padding: 0 10%;
        text-align: center;
        height: 250px;
        width: 100%;
        display: inline-grid;
        background: $blue;
        border: 1px solid $gold;
        @include media-breakpoint-down(md) {
          height: initial;
          font-size: 26px;
        }

        span {
          color: $gold;
        }
      }
    }
  }

}
