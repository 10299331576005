/*
|
| Page Loader
|--------------
*/
body:not(.home) {
  .page-loader {
    display: none;
  }
}

.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  display: none;

  &.active {
    display: flex;
  }

  .logo-loader {
    position: absolute;
    top: 0;
    left: 0;
  }

  .item-content {
    width: 100%;
    height: 100vh;
    color: $very-dark-grey;
    text-align: center;
  }

  .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto;


    .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: $gold;
      animation: loadbar 1.2s cubic-bezier(.92, 0, .09, 1);
      transform-origin: left top;
    }
  }

  #swiper-loading {
    position: fixed;
    inset: 0;

    margin: auto;

    height: 550px;

    .swiper-slide {
      background-color: #181d24;

      .img-loader {
        margin: auto;
        height: 550px;

        img {
          max-width: 400px;
          width: 100%;
          height: 100%;
          display: block;
          margin: auto;
          border: 10px solid #fff;
          object-fit: cover;
          object-position: center;
          box-sizing: border-box;
          @include media-breakpoint-down(sm) {
            max-width: 250px;
          }
        }
      }
    }
  }

  .loader-last-image {
    position: fixed;
    inset: 0;
    z-index: 10;

    margin: auto;

    width: 400px;
    height: 550px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    outline: 8px solid $white;
  }
}

@keyframes loadbar {
  from {
    transform: scaleX(0)
  }
  to {
    transform: scaleX(0.7)
  }
}