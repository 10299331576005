/*
|--------------------
|     PAGE HOME
|--------------------
*/
.home {
  #header {
    .item-burger2 {
      span {
        background-color: white !important;
      }
    }

    &.fixed {
      .item-burger2 {
        span {
          background-color: $gold !important;
        }
      }
    }
  }

  .bannerhome {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      opacity: 0.3;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
    }

    .item-content {
      @include media-breakpoint-up(md) {
        &.titlesize {
          bottom: 20px;

          h1 {
            font-size: 60px;
            line-height: 65px;
          }
        }
      }
    }

    h1 {
      color: #ffffff;
      font-family: "GT Alpina Trial - Th";
      font-size: 80px;

      @include media-breakpoint-down(lg) {
        font-size: 70px;
      }
      @include media-breakpoint-down(md) {
        font-size: 54px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 42px;
        margin-top: 120px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 26px;
      }

      @include media-breakpoint-down(xs) {
        .item-line {
          text-align: center !important;
        }
      }

      span {
        color: $gold;
        font-family: "GT Alpina Trial - Th It";
      }
    }

    .cms {
      color: #ffffff;
      font-family: "FS Meridian";
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      @include media-breakpoint-down(xs) {
        text-align: center !important;
      }
    }

    @include media-breakpoint-down(xs) {
      a {
        margin: auto;
        display: table;
      }
    }
  }

  .section-intro {
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    .bgi {
      background-position: center;
      background-size: cover;
      min-height: 900px;
      @include media-breakpoint-down(md) {
        min-height: 280px;
      }
    }

    h2 {
      position: relative;

      span {
        &:before {
          content: "";
          position: absolute;
          left: -95px;
          top: 0;
          width: 23px;
          height: 68px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../img/icon/quote.svg');
          @include media-breakpoint-down(md) {
            width: 13px;
            height: 58px;
            left: -10px;
          }
        }

        &:after {
          content: "";
          position: absolute;
          right: 0px;
          bottom: 0;
          width: 23px;
          height: 68px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../img/icon/quote.svg');
          transform: rotate(180deg);
          @media(min-width: 1372px) {
            position: relative;
            margin: 0 0 -10px 70px;
            display: inline-block;
          }
          @media(max-width: 1200px) {
            position: absolute;
            margin: 0;
            display: inline-block;
          }
          @include media-breakpoint-down(md) {
            width: 13px;
            height: 58px;
            right: -10px;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      h2 {
        position: relative;

        br {
          display: none;
        }
      }
    }
  }

  .section-real {
    background-color: $blue;

    .img-real {
      height: 650px;
      @include media-breakpoint-down(md) {
        height: 420px;
      }

      h2 {
        font-family: "GT Alpina Trial - Th";
        font-size: 120px;
        color: #fff;
        text-align: left;
        width: 100%;
        bottom: 45px;
        left: 0;
        right: 0;
        position: absolute;
        max-width: 72%;
        margin: auto;
        @include media-breakpoint-down(md) {
          font-size: 80px;
          bottom: initial;
        }
        @include media-breakpoint-down(sm) {
          font-size: 50px;
          text-align: center;
        }

        span {
          height: 250px;
          display: block;
          position: relative;
          @include media-breakpoint-down(md) {
            height: 140px;
          }

          i {
            position: absolute;
            right: 0;
            bottom: -40px;
            @include media-breakpoint-down(sm) {
              position: relative;
              display: block;
              bottom: 0;
            }
          }
        }
      }
    }

    .cms {
      opacity: 0.7;
      color: #ffffff;
    }

    .img-real {
      background-position: center;
      background-size: cover;
    }
  }

  .section-real-text {
    background-color: $blue;

    .cms {
      opacity: 0.7;
      color: #ffffff;
    }
  }

  .section-sup {
    background-color: $blue;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 900px;
    z-index: 0;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-top: 40px;
      height: 500px;
    }

    &:before {
      content: "";
      position: absolute;
      opacity: 0.5;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
    }

    h3 {
      color: #fff;
      text-align: left;
      @include media-breakpoint-down(lg) {
        font-size: 50px;
      }
      @include media-breakpoint-down(md) {
        font-size: 40px;
        text-align: center;
      }
    }

    .m-20 {
      margin-top: -20px;
    }

    .cms {
      opacity: 0.7;
      color: #ffffff;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .btn {
      @include media-breakpoint-down(md) {
        margin: auto;
        display: table;
      }
    }

  }

  #footer {
    background: $beige;
  }
}