/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  z-index: 10;
  position: absolute;
  transition: all $easeInOutCubic 0.4s;

  .logo-scroll {
    display: none;
  }

  &.fixed {
    position: fixed;
    background-color: #f9f9f2;
    transition: all $easeInOutCubic 0.4s;

    .header-container {
      padding-top: 15px;
      padding-bottom: 15px;
      transition: all $easeInOutCubic 0.4s;
      .logo-scroll {
        display: block;
      }

      .item-logo {
        .logo-head {
          display: none;
        }
      }

      img {
        height: 36px;
        transition: all $easeInOutCubic 0.4s;
      }

      &.fixedup {
        opacity: 0;
        background-color: transparent;
        transition: all $easeInOutCubic 0.4s;
      }

      &.fixeddown {
        opacity: 1;
        background-color: #f9f9f2;
        transition: all $easeInOutCubic 0.4s;
      }

      .item-link,
      .lang-container {
        color: #141414 !important;
      }

      .menu-secondary {
        top: 45px;
        background: #F9F9F3 !important;
      }
    }
  }

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: all $easeInOutCubic 0.4s;

    .item-logo {
      @include media-breakpoint-down(sm) {
        img {
          max-height: 55px;
        }
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        li {
          margin-right: 40px;

          .item-link {
            @extend .link-menu;
            color: #ffffff;
            font-family: "Helvetica Neue";
            font-size: 14px;
            font-style: normal;
            letter-spacing: 1.5px;
            line-height: normal;
            text-align: left;
            text-transform: uppercase;
            transition: all ease 0.3s;

            &:hover {
              color: $gold;
              transition: all ease 0.3s;
            }
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          //margin-right: 25px;
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    padding: 0 50px 0 0;
    @extend .link-menu;
    cursor: pointer;
    color: #ffffff;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 1.5px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    .lang-item {
      margin-bottom: 4px;
    }

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;

      li {
        color: #ffffff;

        a {
          color: #ffffff;
          transition: all ease 0.3s;

          &:hover {
            color: $gold;
            transition: all ease 0.3s;
          }
        }
      }
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 32px;

      & > span {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #d8d8d8;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .menu-secondary {
    position: absolute;
    top: 50px;
    right: 0;
    text-align: right;
    display: none;
    overflow: hidden;
    padding: 5px 10px;

    li {
      padding-bottom: 23px;

      a {
        color: #ffffff;
        font-family: "Helvetica Neue";
        font-size: 14px;
        font-style: normal;
        letter-spacing: 1.5px;
        line-height: 22px;
        text-align: right;
        text-transform: uppercase;
        transition: all ease 0.3s;

        &:hover {
          color: $gold;
          transition: all ease 0.3s;
        }
      }
    }
  }

  .btn-menu-secondary {
    position: relative;
    display: block;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: -1px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger2 {
      display: block;
      width: 32px;

      & > span {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $gold;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &.header-w {
    .item-link {
      color: $blue !important;
    }

    .lang-container {
      color: $blue !important;

      .item-lang {
        color: $blue !important;
      }
    }
  }
}

body:not(.home) {
  .menu-secondary {
    &:before {
      content: "";
      background-color: $beige;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  .header-b {
    .menu-secondary {
      &:before {
        content: "";
        background-color: $blue;
      }
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $blue;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          text-transform: uppercase;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;

          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }

}