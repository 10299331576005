/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $blue;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  font-family: "FS Meridian";
  font-size: 16px;
  font-weight: 400;
  background-color: $beige;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "GT Alpina Trial - Th";
  font-weight: 400;
}

h1, h2 {
  font-size: 70px;
  line-height: normal;
  @include media-breakpoint-down(lg) {
    font-size: 64px;
  }
  @include media-breakpoint-down(md) {
    font-size: 40px;
  }

  i {
    color: $gold;
    font-family: "GT Alpina Trial - Th It";
  }
}

h3 {
  font-size: 64px;
  line-height: normal;
  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }
  @include media-breakpoint-down(md) {
    font-size: 30px;
  }

  i {
    color: $gold;
    font-family: "GT Alpina Trial - Th It";
  }
}

h4 {
  font-size: 40px;
  line-height: normal;
  @include media-breakpoint-down(lg) {
    font-size: 36px;
  }
  @include media-breakpoint-down(md) {
    font-size: 30px;
  }

  i {
    color: $gold;
    font-family: "GT Alpina Trial - Th It";
  }
}


button, input, textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

a, img, span, button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

.back-link{
  display: table;
  margin: auto;
  color: white;
  opacity: 1;
  transition: all ease 0.4s;
  text-transform: uppercase;
  font-size: 12px;
  &:hover{
    color: white;
    opacity: 0.5;
    transition: all ease 0.4s;
  }
  .arrow-left{
    width: 10px;
    height: 8px;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('../img/icon/arr-gold.svg');
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.z1 {
  position: relative;
  z-index: 1;
}

.md-down-ma {
  @include media-breakpoint-down(md) {
    width: 100%;
    margin: auto;
  }
}

.txt-up {
  text-transform: uppercase;
}

.w50 {
  width: 50%;
  float: left;

  &.txt-l {
    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }

  &.txt-r {
    font-size: 16px;
    @include media-breakpoint-down(md) {
      text-align: right;
    }
  }
}

.disp-desk {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.disp-mob {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.cms {
  color: $blue;
  font-family: "FS Meridian";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  p {
    color: $blue;
    font-family: "FS Meridian";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}

.rel {
  position: relative;
}

.dflex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.btn {
  color: $blue;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 22px 50px;
  background-color: #f9f9f2;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all ease-in-out 0.3s;
  @include media-breakpoint-down(md) {
    font-size: 12px;
    padding: 16px 35px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:hover {
    //background-color: $blue;
    color: $gold;
    transition: all ease-in-out 0.3s;
  }

  &.btn-b {
    background-color: $blue;
    color: #fff;

    &:hover {
      //background-color: #fff;
      color: $gold;
    }
  }
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.txt-r {
  text-align: right;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.m-a {
  margin: auto;
}

.col-2_4,
.col-sm-2_4,
.col-md-2_4,
.col-lg-2_4,
.col-xl-2_4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2_4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 540px) {
  .col-sm-2_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 720px) {
  .col-md-2_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 960px) {
  .col-lg-2_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1140px) {
  .col-xl-2_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.mousezone {
  cursor: none;
}

.mouse_circle {
  position: fixed;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -10px;
  margin-left: -10px;
  background: $gold;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #fff;
  transform: translate(0, 0);
  transition-duration: .2s;
  transition-timing-function: ease-out;
  z-index: 5;
  opacity: 0;

  @include media-breakpoint-down(md) {
    display: none!important;
  }

  &:before {
    content: "";
    width: 4px;
    height: 2px;
    background-image: url('../img/icon/arr.svg');
    background-position: center;
    background-size: cover;
    position: absolute;
    transition: all ease 0.4s;
    transform: rotate(-45deg);
  }

  &.moused {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    transition-duration: .2s;
    transition-timing-function: ease-out;
    opacity: 1;

    &:before {
      content: "";
      width: 17px;
      height: 15px;
      transition: all ease 0.4s;
    }
  }
}

